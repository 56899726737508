import React, { Component } from 'react';
import parse from 'html-react-parser';

class About extends Component {
	render() {
		if (this.props.data) {
			// var name = this.props.data.name;
			var profilepic = 'images/' + this.props.data.image;
			var bio = this.props.data.bio;
			// var contactmessage = this.props.data.contactmessage;
			// var street = this.props.data.address.street;
			// var city = this.props.data.address.city;
			// var state = this.props.data.address.state;
			// var zip = this.props.data.address.zip;
			// var phone = this.props.data.phone;
			// var email = this.props.data.email;
			// var resumeDownload = this.props.data.resumedownload;
		}

		return (
			<section id="about">
				<div className="row">
					<div className="three columns">
						<img
							className="profile-pic"
							src={profilepic}
							alt="Tim Baker Profile Pic"
						/>
					</div>
					<div className="nine columns main-col">
						<h2>About Me</h2>

						<p>{parse(bio ?? '')}</p>
						{/* <div className="row">
							<div className="columns contact-details">
								<h2>Contact Details</h2>
                <p>{contactmessage}</p>
								<p className="address">
									<span>{name}</span>
									<br />
									<span>
										{street}
										<br />
										{city} {state}, {zip}
									</span>
									<br />
									<span>{phone}</span>
									<br />
									<span>{email}</span>
								</p>
							</div>
							<div className="columns download">
								<p>
									<a
										href={resumeDownload}
										target="_blank"
										rel="noreferrer"
										className="button"
									>
										<i className="fa fa-download"></i>Download Resume
									</a>
								</p>
							</div>
						</div> */}
					</div>
				</div>
			</section>
		);
	}
}

export default About;
